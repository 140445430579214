import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { isBrowser } from '../utils';

const LoginPage = () => {
  const auth0 = useAuth0();

  if (isBrowser() && !auth0.isAuthenticated) {
    auth0.loginWithRedirect();
    return <p>Redirecting to login...</p>;
  }

  return <h1>Login Page</h1>;
};

export default LoginPage;
